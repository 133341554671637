.navbar {
    padding: 0 5%;
}


.navbar-toggler {
    border: none !important;
}

.contactUsBtn {
    border: 3px solid !important;
}

.contactUsBtnHomePage:hover {
    background-color: white !important;
    color: #013451 !important;
}

.contactUsBtnHomePage {
    font-weight: bold;
    padding: 10px 20px !important;
    font-size: 1.2em !important;
}

.btn {
    border: none;
    font-weight: bold;
}

/* Footer */
.footer_logo {
    width: auto;
    height: 120px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.footer {
    color: white;
    width: 100%;
}

a {
    color: white;
}

.sendEmailBtn {
    background-color: #4aadd7 !important;
    color: white !important;
    font-weight: bold;
}

.bulletPointCircle {
    background-color: #EC9706;
    color: white;
    border-radius: 50%;
    padding: 4px;
    width: 32px;
    text-align: center;
}

.flip-card {
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flip-card.flipped .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.flip-card-back {
    transform: rotateY(180deg);
}


@media screen and (max-width: 768px) {
    .contactUsBtn {
        border: none !important;
        padding-left: 0;
    }
}


@media screen and (min-width: 768px) {
    .heroPageText {
        font-size: 1.2em;
    }
}