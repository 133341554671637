h1 {
    /* fon */
    color: #4aadd7;
    /* font-size: 3.1em; */
}

.coaching_image {
    width: 100%;
    min-height: 255px;
    height: 100%;
    max-height: 450px;
    border-radius: 30px;
    object-fit: cover;
    border: 7px solid white;

}

.homeBtn {
    background-color: #4aadd7;
    color: white;
    border: none;
    font-weight: bold;
    padding: 10px 20px;
    font-size: 1.2em;
}

.experience {

    text-align: center;
    padding: 20px 0;
    background-color: #0C97D0;
    /* background-color: #4aadd7; */
    /* background-color: #013451; */
    color: white;
    font-style: oblique;
    font-size: 1.5em;
}

/* .container {

    text-align: center;
} */

.card {
    /* padding: 20px; */
    border: none;
    text-align: left;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.stats {
    /* background-color: #4D9CCA; */
    background-color: #073d56;
    color: white;
    width: fit-content;
}

.stats h2 {
    font-size: 3.5em;
    font-weight: bold;
}

.stats h2:first-of-type {
    color: #D8F3FF;
}

.stats h2:last-of-type {
    color: #2EF2C3;
}

iframe {
    border-radius: 30px;

}

.service_offered_images {
    max-width: 100%;
}

.slick-slide>div {
    margin: 0 10px;
}

.slick-track {
    /* mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 4%, rgb(0, 0, 0) 26%, rgb(0, 0, 0) 74%, rgba(0, 0, 0, 0) 96%); */
    margin: 10px 0;
}

.testimonial {
    /* mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 2%, rgb(0, 0, 0) 10%, rgb(0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%); */

}


/* @media screen and (max-width: 768px) {
    .testimonials {
        mask-image: none;
    }
} */

.logoBanner {
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 2%, rgb(0, 0, 0) 10%, rgb(0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);

}

.logoBanner img {
    width: auto !important;
    height: 80px;
    /* filter: grayscale(100%); */
}

.video-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.video-container .overlay {
    position: absolute;
    top: 0;
    left: auto;
    width: 100%;
    max-Width: 765px;
    height: 100%;
    background-color: #003764;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 30px;
}

.video-container .overlay h1 {
    color: #fff;
    font-size: 2.9em;
    text-align: center;
}

.parent-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* Adjust as needed */
}

a {
    text-decoration: none;
}

/* iframe {
    width: 100%;
    height: 100%;
} */

.everythingDisc .overlay {
    position: absolute;
}

@media screen and (min-width: 769px) {
    .heroImageAdjust {
        display: none;
    }

    .everythingDiscHeroText {
        width: 50%;
    }

    .everythingDiscHeroImage {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .heroImage {
        display: none;
    }

    .everythingDisc .overlay {
        position: relative;
    }

    .everythingDisc {
        background-color: #EDF8FF;
    }

    .everythingDiscHeroText {
        width: 100%;
    }


    .everythingDiscHeroImage {
        max-width: 350px;
    }
}